import { graphql } from "gatsby";
import { gql } from "@apollo/client";

// Article Query - Garsby
export const gatsbyArticleFragment = graphql`
  fragment ArticleFragment on Query {
    allContentfulCpsEeArticle(
      # filter: {compose__page: {elemMatch: {slug: {regex: "/./"}}}}
      sort: {fields: whenPosted, order: DESC}) {
        nodes {
          id
          contentful_id
          title
          timeToRead
          whenPosted
          description {
            description
          }
          articleType {
            slug
          }
          audience {
            slug
            name
          }
          cardImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          # compose__page {
          #   slug
          # }
          categories {
            name
            slug
          }
          analytics {
            pageType
            pageTitle
          }
        }
    }

    # The below has been added for the patchComposePageLink() function
    allContentfulComposePage {
      nodes {
        slug
        id
        content {
          ... on ContentfulCpsEePage {
            id
            name
          }
          ... on ContentfulCpsEeArticle {
            id
            name
          }
        }
      }	
    }
  }
`

// This function is rquired as in the Contentful v3 plugin, content types with not content entries do
// not appear in the schema. It's also not possible to customise the schema for reverse links (compose__page)
// so the graphql commented out must be recreated with an allContentfulComposePage search. The filter must
// also be replaced for the same reason.

export const patchComposePageLink = (data) => {
  let pages={};
  // Build a list of all Compose Pages
  for (const page of data.allContentfulComposePage.nodes) {
    pages[page.content.id]=page;
  }
  // Set up reverse links to the pages
  for (const article of data.allContentfulCpsEeArticle.nodes) {
    if(pages[article.id]) {
      article.compose__page=[pages[article.id]];
    }
  }
  // Filter out any articles which do not have a slug
  data.allContentfulCpsEeArticle.nodes=data.allContentfulCpsEeArticle.nodes.filter(e => !! e?.compose__page?.[0]?.slug)
  
  return(data)
}

// Static Query - preview
// - Note, limited to 100 articles. If more are present, this may not accurately reflect the compiled site
// - This needs refactoring so that the individual components create named fragments for their own use
export const previewArticleFragment = gql`
  fragment ArticleFragment on Query {
    pageArticlePageCollection(limit: 100, order: [whenPosted_DESC]) {
      items {
        sys { id }
        title
        timeToRead
        whenPosted
        description 
        articleType {
          slug
        }
        audience {
          slug
          name
        }
        categoriesCollection(limit: 5) {
          items {
            name
            slug
          }
        }
        cardImage {
          url
        }
        linkedFrom {
          pageCollection(limit: 1) {
            items {
              slug
              sys {id}
            }
          }
        }
        analytics {
          pageType
          pageTitle
        }
      }
    }
  }
`
