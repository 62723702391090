import React from "react";
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider, useQuery, gql } from "@apollo/client";
import { useQueryParam, StringParam } from "use-query-params";
import { stripIgnoredCharacters } from 'graphql';

import Page from "../components/global/Page/page";
import { componentTypes } from "../utils/components";
import { previewFragment as ctaLinkFragments } from "../components/CTALink/CTALink";
import { previewFragment as composeSEOFragment } from "../components/global/Seo/seo";
import { previewFragment as headerFragment } from "../components/global/Header/header";
import { previewFragment as footerFragment } from "../components/global/Footer/footer";
import { previewArticleFragment } from "../utils/articles";

// GraphQL query built from componentTypes

const previewQuery = gql`
  query PreviewQuery($id: String!) {
    page(id: $id, preview: true) {
      slug
      title
      seo {
        ...ComposeSEOFragment
      }
      content {
        ... on PageStandardPage {
          sys {
            id
          }
          header {
            ...HeaderFragment
          }
          footer {
            ...FooterFragment
          }
          analytics {
            pageType
            pageTitle
          }
          containersCollection(limit: 5) {
            items {
              componentsCollection(limit: 15) {
                items {
                  __typename
                  ${Object.keys(componentTypes).map(c => (`...${c}Fragment`)).join("\n")}
                }
              }
            }
          }
        }
        ... on PageArticlePage {
          sys {
            id
          }
          header {
            ...HeaderFragment
          }
          footer {
            ...FooterFragment
          }
          analytics {
            pageType
            pageTitle
          }
          containersCollection(limit: 5) {
            items {
              componentsCollection(limit: 15) {
                items {
                  __typename
                  ${Object.keys(componentTypes).map(c => (`...${c}Fragment`)).join("\n")}
                }
              }
            }
          }
          whenPosted
          timeToRead
          audience {
            slug
            name
          }
          categoriesCollection {
            items {
              slug
              name
            } 
          }
        }
      }
    }
    ...ArticleFragment
  }
  ${composeSEOFragment}
  ${headerFragment}
  ${footerFragment}
  ${ctaLinkFragments}
  ${previewArticleFragment}
  ${Object.values(componentTypes).map(c => c.previewFragment).reduce((frags,frag) => gql`${frags}${frag}`)}
`

// Sub Component

const PreviewComponent = ({ id }) => {
  const { loading, error, data } = useQuery(previewQuery, {
    variables: {
      id: id
    },
  });

  if (loading) return <p>Loading preview...</p>;
  if (error) return <p>Error: <pre>{JSON.stringify(error,null,2)}</pre></p>;
  if (!data.page) return <p>Page not found!</p>;

  return(
    <Page data={data.page} articleData={data.pageArticlePageCollection.items.filter(p => !!p.linkedFrom?.pageCollection?.items?.[0]?.slug)} preview={true} />
  )
}

// Main Component

const PreviewPage = () => {
  const [id] = useQueryParam("id", StringParam);
  const [token] = useQueryParam("token", StringParam);

  if(!token || !id) {
    return(<div>ID and token must be provided.</div>)
  }

  const apolloClient = new ApolloClient({
    link: new HttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`
      },
      print: (ast, originalPrint) => stripIgnoredCharacters(originalPrint(ast))
    }),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={apolloClient}>
      <PreviewComponent id={id}/>
    </ApolloProvider>
  )
}

export default PreviewPage;
